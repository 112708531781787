<script>
import Vue from 'vue';
import GlobalVue from '@helper/Global.vue';
import moment from 'moment';
import Gen from '@helper/Gen';
import FOGen from '@helper/FOGen';
import Croppa from 'vue-croppa';
import swal from 'sweetalert';
import VTooltip from 'v-tooltip'
import {VueMasonryPlugin} from 'vue-masonry';
import VueSocialSharing from 'vue-social-sharing';


/* Vue plugins */
Vue.use(VueMasonryPlugin)
Vue.use(Croppa);
Vue.use(VueSocialSharing);

moment.locale("en")
global.moment = moment

/* CSS */
import "@frontend/css/custom.scss"
import "@frontend/css/responsive.scss"

/* JS */
import "@plugins/jquery/dist/jquery.min.js"
import "@plugins/jquery-validation/jquery-validation.js"
import "@helper/general_function.js"
import "@plugins/extension.js"
import "@frontend/js/main.js"

/* Vue Component */
Vue.component("ImagePreview",()=>import("@backend/components/ImagePreview.vue"))
Vue.component("Component404",()=>import("@frontend/components/Component404.vue"))
Vue.component("VInfo", ()=>import("@frontend/components/VInfo.vue"))
 
global.$ = global.jQuery
global.Gen = Gen
global.App.user = null

export default {
    name:"FoMain",
    extends: GlobalVue,
    async mounted(){
        window.history.scrollRestoration = 'manual'
        global.FOGen = FOGen
        
        /* Load Variable */
        this.refreshVariableFo()
        
        Gen.loadCss("/frontend/css/bootstrap.css")
        Gen.loadCss("/frontend/css/style.css?v=0.1")
        Gen.loadCss("/frontend/css/swiper.css")
        Gen.loadCss("/frontend/css/dark.css")
        Gen.loadCss("/frontend/css/font-icons.css")
        Gen.loadCss("/frontend/css/animate.css")
        Gen.loadCss("/frontend/css/magnific-popup.css")
        Gen.loadCss("/frontend/css/aos.css")
        Gen.loadCss("/frontend/css/custom_l9.css?v=1.60")
        Gen.loadCss("/frontend/css/responsive.css")
        Gen.loadCss("/frontend/css/responsive_l9.css?v=1.60")
        Gen.loadCss("/frontend/css/loader.css")

        await Gen.loadScript("/frontend/js/plugins.js")
        await Gen.loadScript("/frontend/js/functions.js?v=0.01")
        await Gen.loadScript("/frontend/js/aos.js")
        await Gen.loadScript("/frontend/js/custom.js?v=0.2")
    
        global.$ = global.jQuery

        /* Ready Loaded */
        $(document).ready(()=>{
            this.$root.ready = true
        })
        AOS.init({
            once: true
        });
        
        var self = this
        $(document).on("click", "a", function(e){
            if ($(this).is("[reload]")) return;
            if($(this).is("[target]")) return;
            if($(this).attr("data-lightbox")) return;
            if($(this).attr("href")=="javascript:;") return;
            if($(this).attr("href")[0]=="#") return;
            if($(this).attr("href").indexOf(location.origin)>-1){
                e.preventDefault();
                self.$router.push($(this).attr("href").replace(location.origin, ""))
            }
            if($(this).attr("href").indexOf(":")<0){
                e.preventDefault();
                if(self.$route.fullPath!=$(this).attr("href")) self.$router.push($(this).attr("href"))
            }
        })
        $(document).on('keydown', '.form-control', function (e) {
            if (e.which === 32 && e.target.selectionStart === 0) {
                return false;
            }
        });

        this.$root.topProgress = this.$refs.topProgress
        setTimeout(()=>{ $('#footer').css('margin-top','0') },2000)
        $(document).on("click",".pagination a",(e)=>{
            e.preventDefault()
            e.stopPropagation()
        })
    },
    watch: {
        $route(){
            if(this.$route.fullPath.indexOf("resep")<0){
                if(typeof $ != 'undefined') $("html,body").scrollTop(0)
            }
            $(".router-link-active").parent().removeClass("active");
            setTimeout(() => { $(".router-link-active").parent().addClass("active"); }, 100);
            AOS.init({ once: true });
        }
    },
}

$(document).scroll(function() {
    var y = $(this).scrollTop();
    if (y > 200) $('.bottom_navigation').fadeIn();
    else $('.bottom_navigation').fadeOut();
});
</script>

<template>
    <div id="FoMain">
        <router-view/>
    </div>
</template>